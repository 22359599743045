<template>
  <div class="success">
    <div class="container pt-5 pb-5">
      <div class="row justify-content-center pt-5">
        <div class="col-12 d-flex justify-content-center pb-3">
          <span class="container-success-icon">
            <div class="shapeshifter play success-icon"></div>
          </span>
        </div>
        <div class="col-12 d-flex justify-content-center pt-5 pb-5">
          <h1 class="title-mobile">{{$t("saveSuccess")}}</h1>
        </div>
        <div v-if="$route.params.id" class="col-12 pt-4 pb-5">
          <h4 class="title-mobile text-center pb-3" v-html="$t('savedProductId', {productId : $route.params.id})"></h4>
          <h4 class="title-mobile text-center" v-html="$t('savedProductIdSpecsExplain')"></h4>
        </div>
        <div class="col-12 d-flex justify-content-center pt-5 pb-5">
          <button type="button" onClick="window.location.replace(document.referrer);" class="btn btn-danger round pr-3"><i class="fas fa-arrow-left mr-2"></i> {{$t('back')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  
}
</script>


